import React, {useContext, useEffect, useState} from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import {ApiContext} from 'api/api';
import useTranslationLgs from "../../hooks/i18n/useTranslation";
import {Dialog} from "primereact/dialog";

interface ComponentProps {
}


const TimeslotAd: React.FC<ComponentProps> = () => {
    const {tr} = useTranslationLgs();
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Inner>
                <h2>{tr(`TimeslotAd.timeslots`, `Řízení nakládkových a vykládkových oken`)}</h2>
                <p>{tr(`TimeslotAd.description`, `Nechte si své partnery plánovat, kde, kdy a co u vás mohou nakládat a vykládat zboží.`)}</p>
                <a onClick={e => {
                    setVisible(true)
                }}>{tr(`TimeslotAd.interestButton`, `Mam zajem`)}</a>
            </Inner>
            <Dialog onHide={() => setVisible(false)} visible={visible}
                    header={tr(`TimeslotAd.dialogTitle`, `Mám zájem o modul Řízení nakládkových a vykládkových oken`)}>
                <p>{tr(`TimeslotAd.dialogLine1`, `Nechte si své partnery plánovat, kde, kdy a co u vás mohou nakládat a vykládat zboží.`)}</p>
                <strong>{tr(`TimeslotAd.keyFeatures`, `Klíčové vlastnosti`)}</strong>
                <Ul>
                    <li>{tr(`TimeslotAd.feature1`, `Rychlé plánování`)}</li>
                    <li>{tr(`TimeslotAd.feature2`, `Propojení se všemi moduly`)}</li>
                    <li>{tr(`TimeslotAd.feature3`, `Interní spolupráce i spolupráce s partnery`)}</li>
                </Ul>
                <br/>
                <b>{tr(`TimeslotAd.contactUs`, `V případě zájmu o modul nás prosím kontaktujte na`)} <a
                    href={"mailto:podpora@ringil.com?subject=Zájem o modul Řízení nakládkových a vykládkových oken&body=Dobrý den, mám zájem používat modul Řízení nakládkových a vykládkových oken. Prosím o poskytnutí více informací. Děkuji."}>podpora@ringil.com</a></b>
            </Dialog>
        </>
    );
}

const Ul = styled.ul`
  padding: 5px;
  list-style-position: inside;
  list-style-type: disc;
`

const Inner = styled.div`
  display: flex;
  row-gap: 0.4rem;
  padding: 1rem 2rem;
  flex-direction: column;
  box-shadow: 0px 0px 5px rgb(102 102 102 / 35%);
`

export default TimeslotAd;
