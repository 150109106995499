import React, {useContext, useEffect, useState} from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import {ApiContext} from 'api/api';
import useTranslationLgs from "../../hooks/i18n/useTranslation";
import {Dialog} from "primereact/dialog";

interface ComponentProps {
}


const CarrierAd: React.FC<ComponentProps> = () => {
    const {tr} = useTranslationLgs();
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Inner>
                <h2>{tr(`CarrierAd.carrier`, `Carrier`)}</h2>
                <p>{tr(`CarrierAd.description`, `Získejte přehled o čekajících poptávkách a mějte pod kontrolou přiřazené přepravy.`)}</p>
                <a onClick={e => {
                    setVisible(true)
                }}>{tr(`CarrierAd.interestButton`, `Mam zajem`)}</a>
            </Inner>
            <Dialog onHide={() => setVisible(false)} visible={visible}
                    header={tr(`CarrierAd.dialogHeader`, `Mám zájem o modul Dopravce`)}>
                <p>{tr(`CarrierAd.dialogLine1`, `Získejte přehled o čekajících poptávkách a mějte pod kontrolou přiřazené přepravy.`)}</p>
                <strong>{tr(`CarrierAd.keyFeatures`, `Klíčové vlastnosti`)}</strong>
                <Ul>
                    <li>{tr(`CarrierAd.feature1`, `Úplně zdarma`)}</li>
                    <li>{tr(`CarrierAd.feature2`, `Seznam poptávek s možností úpravy již zaslané nabídky`)}</li>
                    <li>{tr(`CarrierAd.feature3`, `Jednoduchý způsob poskytnutí informace o naložení / vyložení`)}</li>
                    <li>{tr(`CarrierAd.feature4`, `Snadné nahrání dokumentů (CRM, faktur, …)`)}</li>
                </Ul>
                <br/>
                <b>{tr(`CarrierAd.contactUs`, `V případě zájmu o modul nás prosím kontaktujte na`)} <a
                    href={"mailto:podpora@ringil.com?subject=Zájem o modul Dopravce&body=Dobrý den, mám zájem používat modul Dopravce. Prosím o poskytnutí více informací. Děkuji."}>podpora@ringil.com</a></b>
            </Dialog>
        </>
    );
}

const Ul = styled.ul`
  padding: 5px;
  list-style-position: inside;
  list-style-type: disc;
`

const Inner = styled.div`
  display: flex;
  row-gap: 0.4rem;
  padding: 1rem 2rem;
  flex-direction: column;
  box-shadow: 0px 0px 5px rgb(102 102 102 / 35%);
`

export default CarrierAd;
