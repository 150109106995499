import { withAuthenticationRequired } from '@auth0/auth0-react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useContext } from 'react';
import { AuthenticatedUserContext } from '@components/AuthenticatedUser/AuthenticatedUser';
import Layout from '@components/Layout';
import useTranslationLgs from '../hooks/i18n/useTranslation';
import { Card } from 'primereact/card';
import ModuleDashboard from '../components/ModuleDashboard/ModuleDashboard';

const PageDashboard = () => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <Layout title={tr(`index.homepage`, `Homepage`)}>
        <Card>
          <ModuleDashboard />
        </Card>
      </Layout>
    </>
  );
};
export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});
export default withAuthenticationRequired(PageDashboard);
